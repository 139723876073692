<template>
  <div class="card card-custom card-stretch gutter-b" v-loading="loading">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">決済の管理</h3>
      </div>
      <div class="card-toolbar">
      </div>
    </div>
    <div class="card-body">
      <div class="row row-custom">
        <div class="col-md-3">
            <label class="label2-custom-50">キーワード</label>
            <el-input v-model="form.booking_id" placeholder="予約番号"></el-input>
        </div>

        <div class="col-md-5">
            <label class="label-custom-50" label-width="50%">決済期間</label><br/>
            <el-date-picker
                v-model="form.time"
                type="datetimerange"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                :default-time="['12:00:00']"
                value-format="yyyy-MM-dd HH:mm:ss"
                >
            </el-date-picker>
        </div>

        <div class="col-md-2">
          <el-row>
            <el-button size="medium" @click="clickSearch()" type="primary">検索</el-button>
          </el-row>
        </div>
      </div>
      <hr />
      <div class="d-flex flex-row justify-content-between align-items-center">
        <span class="demonstration">
          <my-pagination-top style="padding-left:8px;  padding-bottom:10px"
                              :total-record="parseInt(data.total)"
                              :current-page="parseInt(queryParam.page)"
                              :page-size-default="parseInt(data.per_page)"
                              @handleSizeChange="handleSizeChange"
          />
        </span>
        <el-button class="button-csv" size="medium" type="primary" @click="exportCsv()">CSV出力</el-button>
      </div>
      <el-table
        :fit="true"
        border
        :data="data.data"
        style="width: 100%"
        :row-class-name="tableRowClassName">

        <el-table-column
          prop="status"
          label="ステータス"
          align="center"
          >
          <template slot-scope="scope">
            <div v-if="scope.row.status == 0">
              <span style="color: rgb(241, 196, 15);">
                未決済
              </span>
            </div>
            <div v-else >
              <span style="color: rgb(0, 179, 0);">
                決済済
              </span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="order.code"
          label="予約番号"
          min-width="50"
          align="center">
          <template slot-scope="scope">
            <div class="text-left" v-if="scope.row.order">
              <el-link type="primary">
                <router-link
                    :to="{
                  name: 'order-detail',
                  params: { id: scope.row.order.id },
                }"
                >
                  {{ scope.row.order.code }}
                </router-link>
              </el-link>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="customer.name"
          label="お名前"
          align="center"
          >
          <template slot-scope="scope">
            <div class="text-left">
              {{ scope.row.customer ? scope.row.customer.name : '' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="customer.phone"
          label="電話番号"
          align="center"
          min-width="60"
          >
          <template slot-scope="scope">
            <div class="text-left">
              {{ scope.row.customer ? scope.row.customer.phone : '' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="customer.email"
          label="メールアドレス"
          min-width="110"
          align="center"
          >
          <template slot-scope="scope">
            <div class="text-left">
              {{ scope.row.customer ? scope.row.customer.email : '' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="amount"
          label="決済金額"
          min-width="50"
          align="center">
          <template slot-scope="scope">
            <div class="text-right">
              <span>{{formatNumber(scope.row.amount)}} 円</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="updated_at"
          label="決済日"
          width="180"
          align="center">
          <template slot-scope="scope">
          <div class="text-center">
            <span>{{scope.row.updated_at}}</span>
          </div>
        </template>
        </el-table-column>

        <el-table-column
          prop="payment_code"
          label="オーダーID"
          align="center"
          min-width="70">
          <template slot-scope="scope">
            <div class="text-left">
                <span>
                  {{scope.row.payment_code}}
                 </span>
            </div>
          </template>
        </el-table-column>

      </el-table>
      <div v-if="data" class="row float-right pr-5 pt-7">
        <my-pagination
          :total-record="parseInt(data.total)"
          :currentPage="parseInt(data.current_page)"
          :page-size-default="parseInt(data.per_page)"
          @handleCurrentChange="handleCurrentChange"
          @handleSizeChange="handleSizeChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import { LIST_TRANSACTION, EXPORT_TRANSACTION } from "@/core/services/store/transaction.module";
import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

export default {
  name: "ListTransaction",
  data() {
    return {
      data: {},
      loading: false,
      querySearch: {
        page: "",
      },
      search: "",
      form: {
        booking_id: '',
        time: '',
      }
    };
  },

  computed: {
    ...mapGetters(["currentUser"]),
    queryParam() {
      return this.$route.query;
    },
  },

  watch: {
    queryParam() {
      this.getList();
    },
  },

  async created() {
    this.form.booking_id = this.$route.query.booking_id ?? '';
    this.form.time = this.$route.query.time ?? '';
    await this.getList();
  },

  methods: {
    async getList() {
        this.loading = true;
        let params = this.serialize(this.queryParam);
        let data = await this.$store.dispatch(LIST_TRANSACTION, params);
        if (data.code == SUCCESS) {
            this.data = data.data
        }
        this.loading = false;
    },

    handleSizeChange(val) {
      this.$router.push({
        name: "transaction",
        query: { ...this.queryParam, ...{ size: val, page: 1 } },
      });
    },

    handleCurrentChange(val) {
      this.$router.push({
        name: "transaction",
        query: { ...this.queryParam, ...{ page: val } },
      });
    },

    clickSearch()
    {
      this.$router.push({
          name: "transaction",
          query: {...this.queryParam, ...this.form, ...{page: 1}}
      }).catch(err => {
        this.getList();
      });
    },

    getOverallIndex(index) {
      return (this.data.current_page * this.data.per_page) - this.data.per_page + (index + 1)
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    async exportCsv() {
      let dataEngineer = await this.$store.dispatch(EXPORT_TRANSACTION, this.serialize(this.form));
      this.exportCsvCommon("決済一覧_", dataEngineer);
    },
  }
  
};
</script>
<style scoped src="@/assets/css/custom.css">
</style>